//ANIMATED STYLE FOC
import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Icon } from "@ryerson/frontend.assets";
import { ContentSection, Media, Flex, FlexItem } from "@ryerson/frontend.layout";
import { useApplication } from "@ryerson/frontend.application";
import { Typography } from "@ryerson/frontend.typography";
import { useStaticQuery, graphql } from "gatsby";
import { Accordion, AccordionTab } from "@ryerson/frontend.expansion";
import CompanyCard from "@components/FamilyOfCompanies/FamilyOfCompaniesRefactor/CompanyCard";
import CompanyCardMobile from "@components/FamilyOfCompanies/FamilyOfCompaniesRefactor/CompanyCardMobile";
import { Link } from "@ryerson/frontend.navigation";

export type FoCCategoryContent = {
	contentful_id: string;
	slug: string;
	specialtyTitle: string;
	specialtyDescription: SpecialDescriptions;
	order: number;
	node_locale: "en-US" | "fr-CA" | "es-MX";
	companies: any[];
};

export type SpecialDescriptions = {
	specialtyDescription: string;
};

const HeaderBox = styled.div`
	width: 100%;
	box-sizing: border-box;
	position: relative;
`;

const CategorySection = styled.div`
	transition: max-height 0.35s, opacity 1s ease-in-out;
	overflow: hidden;
	margin-top: 10px;
`;

const FoCCategorySectionDesktop: React.FC<any> = ({ content }) => {
	const { theme } = useTheme();
	const [expanded, setExpanded] = React.useState(true);

	return (
		<>
			<div
				css={css`
					padding-bottom: 80px;
				`}
			>
				<HeaderBox>
					<Typography
						variant="h2"
						css={css`
							display: inline-block;
						`}
					>
						{content.specialtyTitle}
					</Typography>
					<div
						onClick={() => {
							setExpanded(!expanded);
						}}
						css={css`
							position: absolute;
							right: 0;
							top: 25%;
							transition: 1s linear all;
							border: 1px solid
								${expanded
									? theme.colors.primary.primaryBrand
									: theme.colors.primary.secondaryBrand};
							border-radius: 24px;
							width: 24px;
							height: 24px;
							text-align: center;
							line-height: 24px;
							transform: rotateX(${expanded ? "180deg" : "0deg"});
							transition: 0.35s transform linear, 0.35s border-color linear;
						`}
					>
						<Icon
							icon="chevron-up"
							size="xs"
							color={theme.colors.primary.secondaryBrand}
						/>
					</div>
				</HeaderBox>
				<Typography
					variant="p"
					css={css`
						color: ${theme.colors.secondary.darkBlue};
						margin-top: 60px;
					`}
				>
					{content.specialtyDescription.specialtyDescription}
				</Typography>
				<CategorySection
					css={css`
						max-height: ${expanded
							? Math.ceil(content.companies.length / 2) * 400 + "px"
							: "0px"};
					`}
				>
					<Flex
						direction="row"
						wrap="wrap"
						alignItems="center"
						justifyContent="flex-start"
						css={css`
							gap: 40px;
						`}
					>
						{content.companies
							.sort((a: any, b: any) => {
								if (a.companyName < b.companyName) {
									return -1;
								}
								if (a.name > b.name) {
									return 1;
								}
								return 0;
							})
							.map((company: any, index: number) => {
								return (
									<FlexItem
										css={css`
											opacity: ${expanded ? 1 : 0};
											transition: 0.35s opacity linear;
										`}
										key={index}
									>
										<CompanyCard content={company} />
									</FlexItem>
								);
							})}
					</Flex>
				</CategorySection>
			</div>
		</>
	);
};

const FoCCompanies: React.FC = () => {
	const queryData = useStaticQuery(graphql`
		query FOCs {
			allContentfulFoCCompany {
				group(field: contentful_id) {
					edges {
						node {
							id
							companyName
							mainFoCBlurb
							slug
							node_locale
							certifications {
								displayName
								downloadableFile {
									file {
										url
									}
								}
							}
							companyType {
								slug
								specialtyTitle
								specialtyDescription {
									specialtyDescription
								}
								order
							}
							heroBody {
								heroBody
							}
							heroImage {
								file {
									url
								}
							}
							heroVideo
							industriesServed {
								blurb {
									blurb
								}
								title
								image {
									file {
										url
									}
								}
							}
							mainFoCImage {
								file {
									url
								}
							}
							productsServices {
								productOrService
								productServiceTitle
								productServiceImage {
									file {
										url
									}
								}
								productServiceList {
									raw
								}
							}
							logo {
								file {
									url
								}
							}
							heroSubheader {
								heroSubheader
							}
							locations {
								slug
								locationName
								streetAddress
								city
								state
								zipCode
								locationHours {
									mondayOpen
									mondayClose
									tuesdayClose
									tuesdayOpen
									wednesdayClose
									wednesdayOpen
									thursdayOpen
									thursdayClose
									fridayClose
									fridayOpen
									saturdayOpen
									saturdayClose
									sundayClose
									sundayOpen
								}
								locationPicture {
									file {
										url
									}
								}
							}
						}
					}
				}
			}
			allContentfulFoCCompanyTypes {
				group(field: contentful_id) {
					edges {
						node {
							node_locale
							slug
							specialtyTitle
							specialtyDescription {
								specialtyDescription
							}
							contentful_id
							order
						}
					}
				}
			}
		}
	`);
	const { theme } = useTheme();
	const {
		localization: { language },
	} = useApplication();

	const categoriesContentful = queryData.allContentfulFoCCompanyTypes;

	let categoriesContent: FoCCategoryContent[] = [];
	const companiesContentful = queryData.allContentfulFoCCompany;

	categoriesContentful.group.forEach((category: any) => {
		category.edges.forEach((edge: any) => {
			if (edge.node.node_locale.split("-").shift() === language) {
				categoriesContent.push(edge.node);
			}
		});
	});

	categoriesContent = categoriesContent
		.map((category: any) => {
			category.companies = [];
			companiesContentful.group.forEach((company: any) => {
				company.edges.forEach((edge: any) => {
					if (edge.node.companyType.slug === category.slug) {
						if (edge.node.node_locale.split("-").shift() === language) {
							category.companies.push(edge.node);
						}
					}
				});
			});
			category.companies.sort((a: any, b: any) => a.order - b.order);
			return category;
		})
		.sort((a: any, b: any) => a.order - b.order);

	return (
		<>
			<ContentSection type="secondary" vPadding="0">
				<Media greaterThanOrEqual="lg">
					<div
						css={css`
							height: 80px;
						`}
					></div>
					{categoriesContent.map((category: FoCCategoryContent, index: number) => {
						return <FoCCategorySectionDesktop content={category} key={index} />;
					})}
				</Media>
				<Media lessThan="lg">
					<Accordion multiple={true} defaultOpen="true">
						{categoriesContent.map((category: FoCCategoryContent, index: number) => {
							return (
								<AccordionTab
									key={index}
									header={category.specialtyTitle}
									circled={true}
									multiple={true}
									tabIndex={2}
									defaultOpen={true}
									maxHeight="15000px"
								>
									<Typography
										variant="p"
										size="md"
										css={css`
											color: ${theme.colors.secondary.darkBlue};
											margin-top: 30px;
										`}
									>
										{category.specialtyDescription.specialtyDescription}
									</Typography>
									<>
										{category.companies
											.sort((a: any, b: any) => {
												if (a.companyName < b.companyName) {
													return -1;
												}
												if (a.name > b.name) {
													return 1;
												}
												return 0;
											})
											.map((company: any, index: number) => {
												return (
													<Link to={company.slug}>
														<CompanyCardMobile content={company} />
													</Link>
												);
											})}
									</>
								</AccordionTab>
							);
						})}
					</Accordion>
				</Media>
			</ContentSection>
		</>
	);
};

export default FoCCompanies;
